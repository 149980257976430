import React, { useRef, useState } from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import Slider from 'react-slick'

import CenteredContent from '../common/CenteredContent';
import ContentRow from '../common/ContentRow';
import InvestorsMobileSlider from '../common/InvestorsMobileSlider';

import LarsRasmussen from '../../assets/images/investors/lars.png';
import RalphBaxter from '../../assets/images/investors/ralph.png';
import JasonBarnwell from '../../assets/images/investors/jason.png';
import DavidKerr from '../../assets/images/investors/david.png';
import StephanieHamon from '../../assets/images/investors/stephanie.png';
import SteveAlbrecht from '../../assets/images/investors/steve.png';
import BenDavey from '../../assets/images/investors/ben.png';
import GaryTully from '../../assets/images/investors/gary.png';
import ChrisGrant from '../../assets/images/investors/chris.png';

import CloseIcon from '../../assets/images/icons/close-white.svg';
import ArrowLeftIcon from '../../assets/svg/arrow-left.svg';
import ArrowRightIcon from '../../assets/svg/arrow-right.svg';

function Investors({ className }) {
  const investors =
    [
      {
        index: 0,
        name: 'Lars Rasmussen',
        description: 'Lars is a world renowned computer scientist, having co-founded Google Maps and having previously served as the director of engineering for Facebook. He holds a PhD in computer science from the University of California, Berkeley.',
        image: LarsRasmussen,
        video: '/videos/investors/lars.m4v'
      },
      {
        index: 1,
        name: 'Ralph Baxter',
        description: `Ralph served as Chairman & CEO of Orrick for nearly a quarter of a century. He was pivotal to transforming Orrick from a domestic firm to become one of the world’s most prominent global law firms, with more than 1,100 lawyers in 25 offices across the United States, Europe and Asia. The American Lawyer named Ralph one of the ‘Top 50 Big Law Innovators of the Last 50 years’, and has also been recognised as one of ‘America’s 100 Most Influential Lawyers’ by the National Law Journal.\n\nRalph now focuses on a wide range of projects that share a commonality in that these projects all seek to modernise legal services. His work extends across the legal ecosystem: legal education, law firms, law departments, legal technology companies and public interest projects and regulatory reform. He also devotes his time to thought leadership through various channels including podcasts and various blogs, and continues to be actively involved in seminars and events.`,
        image: RalphBaxter,
        video: '/videos/investors/ralph.m4v'
      },
      {
        index: 2,
        name: 'Jason Barnwell',
        description: 'Jason is an attorney in Microsoft’s Corporate, External and Legal Affairs department. He leads the Microsoft’s Modern Legal team, with previous roles leading Legal Business, Operations and Strategy team, leading the Open Source Software practice group, and counseling the Cloud and AI platform business.',
        image: JasonBarnwell,
        video: '/videos/investors/jason.m4v'
      },
      {
        index: 3,
        name: 'David Kerr',
        description: 'David until recently was CEO of Bird & Bird for 26 years, and during that time built it up to be the leading global law firm advising companies being changed by tech. He has a passion for harnessing legaltech to improve the delivery of legal services, and advises or is involved with several companies and clients in the area.',
        image: DavidKerr,
        video: '/videos/investors/david.m4v'
      },
      {
        index: 4,
        name: 'Stéphanie Hamon',
        description: 'Stéphanie Hamon is the Head of Legal Operations Consulting at Norton Rose Fulbright and a former Managing Director, Head of External Engagement, Legal at Barclays. She has extensive experience developing and implementing commercial and business management strategies for in-house legal departments. Stéphanie led the Barclays team that won "Legal Operations Team of the Year" at the 2019 UK Legal 500 Awards. Prior to joining Barclays, Stéphanie held leadership roles in the client services and business development functions of several global law firms in markets including the UK, Europe and Asia Pacific.',
        image: StephanieHamon,
        video: '/videos/investors/stephanie.m4v'
      },
      {
        index: 5,
        name: 'Steve Albrecht',
        description: 'Steve is a lawyer and tech enthusiast who has worked in private practice, in the US government and in-house between the US and UK over the past twenty five years. He is currently the General Counsel of the operations, technology and group central legal teams at Barclays based in London.',
        image: SteveAlbrecht,
        video: '/videos/investors/steve.m4v'
      },
      {
        index: 6,
        name: 'Ben Davey',
        description: 'Ben is currently CSO of Azerion Group N.V. and an early stage, growth and technology-led investor and adviser. Previously Chief Investment Officer at EFIC1, CEO and Founder of Barclays Ventures, Group Head of Strategy at Barclays, CSO and Head of FIG EMEA at Barclays Investment Bank, M&A and Advisory Banker and Chancery Barrister.',
        image: BenDavey,
        video: null
      },
      {
        index: 7,
        name: 'Gary Tully',
        description: 'Gary is the Head of Legal Operations at Gilead. He has a demonstrated history of working in the biotechnology and high tech industries. In late 2021, Gary’s team was awarded law.com’s ‘Best Legal Ops Team’ having navigated the development of Covid-19 treatment amidst the pandemic.',
        image: GaryTully,
        video: null
      },
      {
        index: 8,
        name: 'Chris Grant',
        description: 'Chris has broad experience working across the legal industry with time spent in both private practice and in-house. The majority of his career has been focused on legal operations, bringing efficiencies to traditional ways of working. Frustrated by the slow pace of change, in an industry which has been resistant to innovating, Chris has most recently been working with LawTech start ups, playing a role in building an ecosystem to support the next generation of legal services and now consults on legal operations with in-house legal teams. He built the LawTech community at Barclays Eagle Labs where he grew a collaboration space for in-house teams, law firms and tech companies. Chris has been featured in the FT Innovative Lawyers ‘Top 10 Intrepreneurs’ and appeared in the Legal 500 GC Powerlist (UK 2020 Change Agenda). Chris is also a member of the LawTech UK Panel.',
        image: ChrisGrant,
        video: '/videos/investors/chris.m4v'
      }
    ];

  const [currentInvestor, setCurrentInvestor] = useState(null)

  const desktopInvestorSlider = useRef(null)

  return (
    <>
      <ContentRow className={`${className || ''} fit-width pb-50 pb-sm-0`}>
        <div className="col-12 col-sm-9">
          <ContentRow className="pb-50 pb-sm-40">
            <CenteredContent
              sectionTitle="Our Investors and Advisors"
            >
              <div className="paragraph"><h4>In an industry steeped with tradition, innovating is not easy. Our success is thanks to the shoulders of these giants, all of whom are experts in their field and who generously share their insights and visions for the future which ensures our products are best-in-class.</h4></div>
            </CenteredContent>
          </ContentRow>
        </div>
      </ContentRow>
      <div className="d-none d-lg-block">
        {
          currentInvestor ?
            <div className="investor-details-container">
              <div className="button-container ">
                <button onClick={() => setCurrentInvestor(null)}>
                  <img src={CloseIcon} />
                </button>
              </div>
              <Slider
                ref={desktopInvestorSlider}
                {...{
                  centerMode: true,
                  centerPadding: 0,
                  speed: 500,
                  arrows: false,
                  initialSlide: currentInvestor.index
                }}
              >
                {investors.map(investor => (
                  <div key={investor.index} >
                    <div className="investor-details">
                      <img src={investor.image} />
                      <div className="details">
                        <h3 className="text-white">{investor.name}</h3>
                        <p className="text-white" style={{whiteSpace: 'pre-line'}}>{investor.description}</p>
                      </div>
                  </div>
                  </div>
                ))}
              </Slider>
              <div className="button-container">
                <button className="mr-2" onClick={() => desktopInvestorSlider.current.slickPrev()}>
                  <ArrowLeftIcon fill="white" />
                </button>
                <button onClick={() => desktopInvestorSlider.current.slickNext()}>
                  <ArrowRightIcon fill="white" />
                </button>
              </div>
            </div>
            :
            <ParallaxProvider>
              <Parallax
                x={[-10, 10]}
              >
                <div className="investors-parallax-container">
                  {
                    investors.slice(0, 5).map(((investor) => (
                    <div
                      key={investor.index}
                      className="investor-container ml-4 mr-4"
                      onClick={() => setCurrentInvestor(investor)}
                    >
                      {investor.video ?
                        <video
                          className="position-relative investor-video"
                          autoPlay
                          muted
                          loop
                          onMouseOver={event => event.target.pause()}
                          onMouseOut={event => event.target.play()}
                        >
                          <source src={investor.video} type="video/mp4" />
                        </video>
                      :
                        <img className="investor-image" src={investor.image} />
                      }
                      <div className="investor-name">{investor.name}</div>
                    </div>
                    )))
                  }
                </div>
              </Parallax>
              <Parallax
                x={[10, -10]}
              >
                <div className="investors-parallax-container">
                  {
                    investors.slice(5).map(((investor, i) => (
                      <div onClick={() => setCurrentInvestor(investor)} key={investor.index} className="investor-container ml-4 mr-4">
                        {investor.video ?
                          <video
                            className="position-relative investor-video"
                            autoPlay
                            muted
                            loop
                            onMouseOver={event => event.target.pause()}
                            onMouseOut={event => event.target.play()}
                          >
                            <source src={investor.video} type="video/mp4" />
                          </video>
                        :
                          <img className="investor-image" src={investor.image} />
                        }
                        <div className="investor-name">{investor.name}</div>
                      </div>
                    )))
                  }
                </div>
              </Parallax>
            </ParallaxProvider>
        }
      </div>
      <InvestorsMobileSlider className={className} />
    </>
  );
}

export default Investors;
