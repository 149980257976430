import React from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import img1 from '../../assets/images/StockPhotos/optimized-photos/about-us-page/about-us-01.png';
import img2 from '../../assets/images/StockPhotos/optimized-photos/about-us-page/about-us-02.png';
import img3 from '../../assets/images/StockPhotos/optimized-photos/about-us-page/about-us-03.png';
import img4 from '../../assets/images/StockPhotos/optimized-photos/about-us-page/about-us-04.png';

function ImageParallax({ className }) {

  return (
    <div className={`image-parallax-widget fit-width ${className} || ""`}>
      <div className="d-none d-md-block">
        <ParallaxProvider>
        <Parallax
          x={[0, -80]}
        >
          <div className="d-flex">
            <img className="rounded" src={img1} />
            <img className="rounded ml-3 mr-3" src={img2} />
            <img className="rounded mr-3" src={img3} />
            <img className="rounded" src={img4} />
          </div>
        </Parallax>
        </ParallaxProvider>
      </div>
      <div className="row d-block d-md-none">
        <div className="col-sm-12">
          <img className="rounded mt-4" src={img1} />
        </div>
        <div className="col-sm-12">
          <img className="rounded mt-4" src={img3} />
        </div>
        <div className="col-sm-12">
          <img className="rounded mt-4" src={img4} />
        </div>
      </div>
    </div>
  )
};

export default ImageParallax