import React from 'react';

import CenteredContent from '../common/CenteredContent';
import ContentRow from '../common/ContentRow';
import NumeratedBenefits from './NumeratedBenefits';

function AboutUsDetailsDown({ className }) {
  return (
    <ContentRow className={`${className || ''} fit-width pt-50 pt-sm-40`}>
      <div className="col-xl-9 col-md-9 col-sm-12">
        <ContentRow className="pt-50 pt-sm-40">
          <CenteredContent>
            <div className="paragraph mb-32 mb-sm-24">
              <h4 className="mb-0">We're still not satisfied. The potential exists to completely revolutionise the practice of law. The start of this decade has delivered LawAdvisor with a new mandate: to overhaul and transform how lawyers work with state of the art legal practice management technology in order to</h4>
            </div>
            <NumeratedBenefits />
          </CenteredContent>
        </ContentRow>
      </div>
    </ContentRow>
  );
}

export default AboutUsDetailsDown;