import React from 'react';

import CenteredContent from '../common/CenteredContent';
import ContentRow from '../common/ContentRow';

function AboutUsDetailsUp({ className, myRef }) {
  return (
    <ContentRow className={`${className || ''} fit-width pb-50 pb-sm-40`}>
      <div ref={myRef} className="col-12 col-sm-9">
        <ContentRow className="pb-50 pb-sm-40">
          <CenteredContent
            sectionTitle="The company"
          >
            <div className="paragraph"><h4>LawAdvisor was founded in 2015 in Melbourne, Australia with a fearless and ambitious belief: <span style={{fontSize: '24px', lineHeight: '40px', letterSpacing: '-0.02em'}} className="text-primary">that everybody should have access to justice.</span></h4></div>
            <div className="paragraph"><h4>We hypothesised that the high costs, inefficiencies, and difficulties of obtaining legal help could be overcome simply by providing greater access to legal information, and by providing everybody with real-time access to high-quality, affordable lawyers online.</h4></div>
            <div className="paragraph"><h4 className="last-paragraph mb-0"> Our mission has since been supported by thousands of lawyers, and has allowed us to expand and set up offices across the globe. We've since moved our HQ to London, and have attracted high profile investors and partners who have shared our mission to transform legal service delivery.</h4></div>
          </CenteredContent>
        </ContentRow>
      </div>
    </ContentRow>
  );
}

export default AboutUsDetailsUp;
