import React, { useRef } from 'react';

import Layout from '../components/layout/Layout';

import PageTitleSection from '../components/common/PageTitleSection';
import FullWidthImage from '../components/common/FullWidthImage';
import AboutUsHero from '../assets/images/StockPhotos/optimized-photos/about-us-page/about-us-hero.png';
import LatestNews from '../components/news/LatestNews';
import ImageParallax from '../components/about-us/ImageParallax';
import AboutUsDetailsDown from '../components/about-us/AboutUsDetailsDown';
import AboutUsDetailsUp from '../components/about-us/AboutUsDetailesUp';
import Investors from '../components/about-us/Investors';

function AboutUs() {
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  return (
    <Layout
      title="Lawyer like never before"
      description="LawAdvisor was founded in 2015 in Melbourne, Australia with a fearless and ambitious belief: that everybody should have access to justice."
      image="/metaimages/meta-image-la-ventures.png"
    >
      <PageTitleSection
        data-enable
        haveArrow
        executeScroll={executeScroll}
      >
        <h2>Breaking the law,</h2>
        <h2 className="mb-0">for good!</h2>
      </PageTitleSection>
      <FullWidthImage img={AboutUsHero} />
      <AboutUsDetailsUp myRef={myRef} />
      <ImageParallax />
      <AboutUsDetailsDown />
      <Investors />
      <LatestNews />
    </Layout>
  );
}

export default AboutUs;
