import React from 'react';

function NumeratedBenefits({ className }) {
  return (
    <div className="row about-us-numerated text-primary">
      <div className="col-xl-6 col-md-6 col-sm-12">
        <div className="row no-gutters mb-32 mb-sm-24"><div className="col-2">i.</div><div className="col-10">increase efficiencies</div></div>
        <div className="row no-gutters mb-32 mb-sm-24"><div className="col-2">ii.</div><div className="col-10">automate routine processes</div></div>
      </div>
      <div className="col-xl-6 col-md-6 col-sm-12">
        <div className="row no-gutters mb-32 mb-sm-24"><div className="col-2">iii.</div><div className="col-10">increase transparency</div></div>
        <div className="row no-gutters mb-32 mb-sm-24"><div className="col-2">iv.</div><div className="col-10">bring legal practice to the 21st century.</div></div>
      </div>
    </div>
  );
}

export default NumeratedBenefits;